import React, { createContext, useContext, useEffect, useState } from "react";
import { getCookieItem, setCookieItem } from "../utils/cookieHelper";

const ThemeContext = createContext();

export const useThemeContext = () => useContext(ThemeContext);

export const ThemeContextProvider = ({ children }) => {
  const initialTheme = getCookieItem("theme") || "dark";
  const [theme, setTheme] = useState(initialTheme);

  useEffect(() => {
    setCookieItem("theme", theme, { maxAge: 30 * 24 * 60 * 60, path: "/" });
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
