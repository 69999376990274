import { parseCookies, setCookie, destroyCookie } from "nookies";

// Get item from cookies
export function getCookieItem(key) {
  const cookies = parseCookies();
  const item = cookies[key];
  if (item) {
    try {
      return JSON.parse(item);
    } catch (error) {
      console.log("error", error);
    }
  }
  return null;
}

// Set item in cookies
export function setCookieItem(key, value, options) {
  try {
    const serializedValue = JSON.stringify(value);
    setCookie(null, key, serializedValue, options);
  } catch (error) {
    console.log("error", error);
  }
}

// Delete item from cookies
export function deleteCookieItem(key) {
  destroyCookie(null, key);
}

// Update item in cookies
export function updateCookieItem(key, updater, options) {
  const prevValue = getCookieItem(key);
  const nextValue = updater(prevValue);
  setCookieItem(key, nextValue, options);
}
